import React from 'react'
import './divider.scss'

const Divider = ({classes}) => {
    return (
        <div className={`divider-wrapper ${classes}`}>
            <div className={`green-divider`}></div>
        </div>
    )
}

export default Divider