import React from 'react'
import { Link } from 'react-router-dom'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import Section from '../../container/Section/Section'
import { Accordion, Container } from 'react-bootstrap'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import Divider from '../../components/Divider/Divider'
import faqs from '../../data/faqs'
import './faqs.scss'

const Faqs = ({data = faqs.general, page}) => {

    const questions = data.questions.filter(question => {
        if(!question.hiddenPages) return true;
        return !question.hiddenPages.includes(page)
    })

    return (
        <Section id='faq'>
            <Container>
                <div className='faqs-section'>
                    <SectionTitle 
                        content={data.title} 
                        classes={'faqs-section-title'} 
                        titleSize={'secondary'}
                    />
                    <Divider />
                </div>
                <Accordion flush>
                    {questions.map(question => {
                        return (
                            <Accordion.Item eventKey={question.id} className='accordion-item' key={question.id}>
                                <Accordion.Header className='faq-question'>{question.title}</Accordion.Header>
                                <Accordion.Body>
                                    <ReactMarkdown className='faq-answer' children={question.text} linkTarget="_blank" />
                                    {question.link && <Link to={question.link} target='_blank' rel="noreferrer">{question.linkTitle}</Link>}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}  
                </Accordion>
            </Container>
        </Section>
    )
}

export default Faqs