import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import Section from '../../../container/Section/Section'
import SectionTitle from '../../SectionTitle/SectionTitle'
import Content from '../../content'
import { v4 as uuidv4 } from 'uuid';
import './textSectionFour.scss'

const TextSectionFour = ({data, classes}) => {
    return (
        <Section classes={'text-section-four-container'}>
            <SectionTitle 
                title={data.title}
                classes={'text-section-four-title'}
            />
            <Content classes={'text-section-four-text'}>
                {data.text.map(text => {
                    return (
                        <ReactMarkdown className='text-grey' children={text} key={uuidv4()} />
                    )
                })}
            </Content>
        </Section>
    )
}

export default TextSectionFour