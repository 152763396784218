import React from 'react';
import './index.scss'

const SectionTitle = ({title, content, classes, type, titleSize}) => {
    const textAlign = type === 'banner' ? 'text-left' : 'text-center';
    const size = titleSize === 'main' ? 'main-title' : 'secondary-title';
    
    return (
        <div className={`section-title ${textAlign} ${classes} ${size} title-section`} style={{maxWidth: '100%'}}>
            <h2 className="title">{title}</h2>
            <h5 className="sub-title">{content}</h5>
        </div>
    );
};

export default SectionTitle;