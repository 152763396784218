import React from 'react'
import ServicesBanner from '../../../components/HeroBanner/ServicesBanner/ServicesBanner'
import TextSectionTwo from '../../../components/TextSection/TextSectionTwo/TextSectionTwo'
import MethodExplanation from '../../../components/services/MethodExplanation/MethodExplanation'
import WorkStepsDescription from '../../../components/services/WorkStepsSection/WorkStepsDescription'
import PriceSection from '../../../components/services/PriceSection/PriceSection'
import Faqs from '../../Faqs/Faqs'
import fitnessService from '../../../data/fitnessService'
import fitnessBg from '../../../assets/images/services/fitness-1.jpg'
import faqs from '../../../data/faqs'
import './fitnessService.scss'

const FitnessService = () => {
    return (
        <main className={'main-wrapper p-0'}>
            <ServicesBanner data={fitnessService.banner} bg={fitnessBg} overlay={true} />
            <TextSectionTwo data={fitnessService.info} classes={''}/>
            <MethodExplanation data={fitnessService.method} />
            <WorkStepsDescription data={fitnessService.workSteps} serviceType={'fitness'}/>
            <PriceSection data={fitnessService.prices} /> 
            <Faqs data={faqs.general} />
        </main>
    )
}

export default FitnessService