import React from 'react'
import Button from '../Buttons/Button/Button';
import './index.scss'

const ServiceItem = ({ title, icon, content, buttonText, buttonPath, calendar }) => {

    return (
        <div className="service-item">
            <div className="service-inner">
                <img src={require('../../assets/images/services/' + icon)} alt={title}/>
                <div className="service-content">
                    <h4>{title}</h4>
                    <p>{content}</p>
                </div>
                <Button 
                    buttonText={buttonText} 
                    buttonClass={'services-home-button'}
                    buttonPath={buttonPath} 
                    calendar={calendar}
                />
            </div>
        </div>
    )
}

export default ServiceItem