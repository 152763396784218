import React from 'react';
import Contact from '../contact/Contact';
import HomeBanner from "../../components/HeroBanner/HomeBanner/HomeBanner";
import AboutMeSection from '../../container/AboutMeSection/AboutMeSection';
import Facts from '../../container/FactsSection/Facts';
import ServiceHomeSection from '../../container/ServiceHomeSection/ServiceHomeSection';
import TextSectionOne from '../../components/TextSection/TextSectionOne/TextSectionOne';
import homeData from '../../data/home'
import './home.scss'
import Media from '../../components/media/Media.jsx';

const Home = () => {
    return (
        <main className={'main-wrapper p-0'}>
            <HomeBanner data={homeData.banner} />
            <Facts data={homeData.facts} />
            <TextSectionOne data={homeData.textSection} classes={'home-text-section'} />
            <AboutMeSection data={homeData.about} />
            <ServiceHomeSection data={homeData.services} />
            <Media />
            <Contact page={false} />
        </main>
    );
};

export default Home;