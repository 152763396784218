import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import Button from '../../Buttons/Button/Button'
import { v4 as uuidv4 } from 'uuid';
import './textSectionTwo.scss'

//estudio diagnostico nutri, estado nutricional, plan alimentario, enfa (bold)

const TextSectionTwo = ({data, classes}) => {

    return (
        <section className={`section general-service-text-section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50`}>
            <div className='text-section-content'>
                <div className='text-wrapper-1'>
                    <div className='text-wrapper-1-inner'>
                        {data.firstParagraph.map(text => {
                            return <ReactMarkdown className='text-green' children={text} key={uuidv4()} />
                        })}
                    </div>
                </div>
                <div className='text-wrapper-2'>
                    <div className='text-wrapper-2-inner'>
                        {data.secondParagraph.map(text => {
                            return <ReactMarkdown className='text-grey' children={text} key={uuidv4()} />
                        })}
                    </div>
                </div>
                {data.buttonText && 
                    <div className='text-section-button-wrapper'>
                        <Button 
                            buttonPath={data.buttonPath} 
                            buttonClass={'text-section-button'} 
                            buttonText={data.buttonText}
                        />
                    </div>
                }
            </div>
        </section>
    )
}

export default TextSectionTwo