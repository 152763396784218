import React from 'react'
import { useEffect } from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
import ServiceItem from '../../components/services/ServiceItem'
import Divider from '../../components/Divider/Divider'
import './index.scss'
import Section from '../Section/Section'

const ServiceHomeSection = ({data}) => {
    
    return (
        <Section id='services'>
            <Container>
                    <SectionTitle 
                        title={data.title} 
                        content={data.subtitle} 
                        classes={'services-title'} 
                    />
                    <Divider classes={'divider'} />

                    <Row className={'mbn-30 services-list'}>
                        {data.services.map(service => (
                            <Col key={service.id} md={4} lg={4} className={'mb-30'}>
                                <ServiceItem 
                                    title={service.title}
                                    content={service.content}
                                    buttonText={service.buttonText}
                                    buttonPath={service.buttonPath}
                                    icon={service.icon}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
        </Section>
        
    )
}

export default ServiceHomeSection