import React from 'react'
import { Container, Row } from 'react-bootstrap'
import SectionTitle from '../../components/SectionTitle/SectionTitle'
//import PriceItem from '../../components/PriceItem/PriceItem'
import PriceItemTwo from '../../components/PriceItem/PriceItemTwo'
import PriceItem from '../../components/PriceItem/PriceItem'
import Divider from '../../components/Divider/Divider'
import Faqs from '../Faqs/Faqs'
import ReactMarkdown from 'react-markdown'
import Section from '../../container/Section/Section'
import prices from '../../data/prices'
import './prices.scss'

const Prices = () => {

    return (
        <main className={'main-wrapper bg-grey pb-80 prices-wrapper'}>
            <SectionTitle 
                title={'servicios'}
                content={'precios'}
                classes={'prices-title'}
            />
            <Divider classes={'prices-divider'} />

            <Section>
                <Container>
                    <Row className='prices-row-3'>
                        <SectionTitle 
                            title={prices.online.title}
                            classes={'prices-title'}
                        />
                        {prices.online.prices.map(price => {
                            return (
                                <PriceItem data={price} key={price.id} calendar={price.calendar} classes={'price-outer-wrapper'}/>
                            )
                        })}
                    </Row>
                    <div className='footnote'>
                        <ReactMarkdown>{prices.online.footnote}</ReactMarkdown>
                    </div>
                </Container>
            </Section>

            <Section classes={'bg-white'}>
                <Container>
                    <Row className='prices-row-1'>
                        {prices.initialPrice.map(price => {
                            return (
                                <PriceItemTwo data={price} classes={'price-detail-2 price-outer-wrapper'} key={price.id} calendar={false} />
                            )
                        })}
                    </Row>
                    <Row className='prices-row-1'>
                        {prices.prices.map(price => {
                            return (
                                <PriceItemTwo data={price} key={price.id} calendar={price.calendar} classes={'price-outer-wrapper'}/>
                            )
                        })}
                    </Row>
                    <Row>
                        <div className='pregnant-footnote'>
                            <p>{prices.pregnantFootnote}</p>
                        </div>
                        <div className='payment-method'>
                            <ReactMarkdown>{prices.paymentMethod}</ReactMarkdown>
                        </div>
                    </Row>
                </Container>
            </Section>
            <Section>
                <Container>
                    <Row>
                        <Faqs page='prices' />
                    </Row>
                </Container>
            </Section>
        </main>
    )
}

export default Prices