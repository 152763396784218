import React from 'react'
import socialicons from '../../data/social-networks/socials.json'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faFacebookF,
    faInstagram,
    faYoutube,
    faTiktok,
} from '@fortawesome/free-brands-svg-icons'
library.add(faFacebookF, faInstagram, faYoutube, faTiktok)

const iconMap = {
'faTiktok': faTiktok,
'faFacebookF': faFacebookF,
'faInstagram': faInstagram,
'faYoutube': faYoutube
}


const SocialMedia = ({title, outerWrapper, classes}) => {
    return (
        <div className="contact-social">
            {title && <h3>Redes Sociales</h3>}
            <div className={`icons-wrapper ${outerWrapper}`}>
                {socialicons.map(social => (
                    <>
                        <div className='icon-container' key={social.id}>
                            <a key={social.id} href={`https://${social.media}.com/${social.username}`} target='_blank' rel='noreferrer'>
                                <FontAwesomeIcon icon={iconMap[social.icon]} className={`fa fa-brands fab ${classes} fa-fw contact-social-icon`}/>
                            </a>
                        </div>
                    </>
                ))}
            </div>
        </div>
    )
}

export default SocialMedia