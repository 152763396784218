import React from 'react'

const Section = ({classes, children, id}) => {
    return (
        <section id={id} className={`section ${classes ?? '' } pt-80 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-80 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50`}>
            <div className={classes}>
                {children}
            </div>
        </section>
    )
}

export default Section