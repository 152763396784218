import React from 'react'
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap'
import Button from '../Buttons/Button/Button'
import { v4 as uuidv4 } from 'uuid';
import './priceItemTwo.scss'

const PriceItemTwo = ({data, classes}) => {

    return (
        <Col lg={data.size} className='price-col'>
            <div className={`priceItemTwo-wrapper ${classes}`}>
                <div>
                    <div>
                        <h1>{data.title}</h1>
                        {data.subtitle && <h2>{data.subtitle}</h2>}
                        <p className='heading'>{data.heading}</p>
                    </div>
                    
                    {data.texts?.map(text => {
                        return (
                            <div className='price-detail' key={uuidv4()}>
                                <p>{text}</p>
                            </div>
                        ) 
                    })}
                </div>
                <div className='price-bottom '>
                    <div className={``}>
                        {data.slashedPrice ? 
                            <div className='item-price'>
                                <h3 style={{textDecoration: 'line-through'}} className='slashed'>{data.slashedPrice}</h3>
                                <h3>{data.price}</h3>
                            </div>
                            :
                            <h3>{data.price}</h3>
                        }
                        <span><i>{data.discount}</i></span>
                    </div>
                    <div className='price-footnote'>{data.footnote}</div>
                    {data.button && data.whatsapp ?
                        <Link to={data.buttonPath} target='_blank' rel="noreferrer" className='about-me-button button-content button-deco'>
                            {data.buttonText}
                        </Link>
                        :
                        <Button 
                            buttonText={data.buttonText}
                            buttonClass={data.buttonClass}
                            buttonPath={data.buttonPath}
                            calendar={data.calendar}
                        />
                    }
                </div>
            </div>
        </Col>
    )
}

export default PriceItemTwo