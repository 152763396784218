import React, {Fragment} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
//import { HashRouter } from 'react-router-dom';
import Layout from './container/Layout/Layout';
import Home from './pages/home/Home';
import AboutPage from './pages/about/AboutMe';
import GeneralService from './pages/services/GeneralService/GeneralService';
import PregnantService from './pages/services/PregnantService/PregnantService';
import FitnessService from './pages/services/FitnessService/FitnessService';
import Prices from './pages/prices/Prices';
import Contact from './pages/contact/Contact';
import Calendar from './components/Calendar/Calendar';
import Faqs from './pages/Faqs/Faqs';

function App() {
    return (
        <Fragment>
            {/*<Switcher />  switch page colour, doesn't work */}
            <Router>
                <Routes>
                    <Route path='/' element={ <Layout/> } > {/* there is a problem using Layout */}
                        <Route index path='/' element={ <Home /> }/>
                        <Route path='/sobre-mi' element={ <AboutPage /> } />
                        <Route path='/plan-nutricional' element={ <GeneralService /> } />
                        <Route path='/plan-nutricional-embarazo' element={ <PregnantService /> } />
                        <Route path='/plan-nutricional-deportes' element={ <FitnessService /> } />
                        <Route path='/precios' element={ <Prices /> } />
                        <Route path='/contacto' element={ <Contact /> } />
                        <Route path='/faqs' element={ <Faqs /> } />
                        <Route path='/citas' element={ <Calendar /> } />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Route>
                </Routes>
            </Router>
        </Fragment>
    );
}

export default App;
