import React, { useEffect, useRef } from 'react';
import { InlineWidget } from "react-calendly";

const Calendar = () => {
    return (
        <div className='calendar'>
            <InlineWidget url="https://calendly.com/your_scheduling_page" />
            
           {/*  <script>
                {
                    calendarLink = () => {

                    }
                }
                (function() {
                var target = document.currentScript;
                window.addEventListener('load', function() {
                    calendar.schedulingButton.load({
                    url: 'https://www.doctoralia.es/lucila-glom/dietista-nutricionista/valencia',
                    color: '#009688',
                    label: 'Reservar una cita',
                    target,
                    });
                });
                })();
            </script> */}

        </div>
    )
}

export default Calendar