import React from 'react'
import Section from '../../container/Section/Section'
import './media.scss'

const Media = () => {
    return (
        <Section classes={`bg-white ${'media-wrapper'}`}>
            <div className="video-media-youtube">
                <div className="video-media-youtube-inner">
                    <div className="video-media-youtube-inner-video">
                        <div className="sixteen-by-nine">
                            <iframe 
                                src="https://www.youtube.com/embed/kIJgUWLLxXM"
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowFullScreen    
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default Media