import React, { useState, useEffect } from 'react'
import Content from '../content'
import './policy.css'
import '../Buttons/Button/button.scss'

const PrivacyPolicy = () => {
    const [consent, setConsent] = useState(false);

    const handleConsentChange = (e) => {
        setConsent(e.target.checked);
    };

    return (
        <Content classes='policy-wrapper'>
            <div className='policy-content'>
                <input 
                    type="checkbox" 
                    checked={consent} 
                    onChange={handleConsentChange} 
                    required={true}
                    className='policy-checkbox'
                />
                <label> He leído y acepto la <a href='/privacypolicy.html' className='policy-link' target='_blank'>Política de Privacidad.</a></label>
            </div>
        </Content>
    )
}

export default PrivacyPolicy