import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import {Container, Row, Col} from 'react-bootstrap'
import Thumbnail from "../../components/thumbnail";
import Content from "../../components/content";
import Button from '../../components/Buttons/Button/Button';
import Section from '../Section/Section';
import { v4 as uuidv4 } from 'uuid';
import './aboutMeSection.scss'
import { Link } from 'react-router-dom';

const AboutMeSection = ({type, data}) => {

    return (
        <Section classes={'about-me-home-section'}>
            <Container>
                <Row className={'align-items-center about-me-inner'}>
                    <Col lg={7} className='about-text-wrapper'>
                        <Content classes={'about-content'}>
                            <div>
                                <h2 className="about-me-title">{data.title}</h2>
                                {data.text.map(detail => {
                                    return <ReactMarkdown className='about-me-text' children={detail} key={uuidv4()} />
                                })}
                            </div>
                            <div className='free-consultation'>
                                <p className='free-consultation-title'>{data.subtitle}</p>
                                <ReactMarkdown children={data.detail} />
                            </div>
                            {/* <Button 
                                buttonText={data.buttonText} 
                                buttonClass={'about-me-button'}
                                buttonPath={data.buttonPath}
                                buttonIcon={true}
                            /> */}
                            <Link to={data.buttonPath} target='_blank' rel="noreferrer" className='about-me-button button-content button-deco'>
                                {data.buttonText}
                                {data.buttonIcon && <i className="fa fa-angle-double-right button-deco" aria-hidden="true"></i>}
                            </Link>
                        </Content>
                    </Col>
                    <Col lg={5}>
                        <Thumbnail classes={'about-thumbnail mb-md-30 mb-sm-30 mb-xs-30 photo-profile'} thumb={`about/${data.thumb}`}/>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default AboutMeSection;