import React from 'react';
import Logo from "../../logo";
import NavBar from '../../../container/Navbar/Navbar.js';
import HeaderSocial from "../../HeaderSocial";
import HeaderContainer from '../../../container/Header';
import './headerOne.scss'

const Header = ({classes}) => {
    return (
        <HeaderContainer classes={classes ? classes : null}>
            <Logo classes={'logo-header'}/>

            <NavBar/>


            <HeaderSocial/>
        </HeaderContainer>
    );
};

export default Header;
