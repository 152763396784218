import React from 'react';
import ReactMarkdown from 'react-markdown'
import HeroBannerContainer from "../../../container/HeroBanner";
import Divider from '../../Divider/Divider';
import bannerBg from '../../../assets/images/hero/1.svg'
import "./homeBanner.scss"
import SectionTitle from '../../SectionTitle/SectionTitle';
import Button from '../../Buttons/Button/Button';
import { HashLink } from 'react-router-hash-link';


const HomeBanner = ({data}) => {

    return (
        <HeroBannerContainer  bg={bannerBg} classes={'home-banner'} ids={'banner-container'}>
            <div className="main-slider-content banner-content">
                <div className='banner-text-container'>
                    <SectionTitle 
                        title={data.title}
                        content={data.subtitle}
                        classes={'home-banner-title'}
                        type={'banner'}
                        titleSize={'main'}
                    />
                    <Divider classes={'home-banner-divider'} />
                    <div>
                        <ReactMarkdown className='home-banner-text' children={data.text} />
                    </div>
                </div>

                <div className='call-to-action'>
                    <div className='call-to-action-buttons'>
                        {data.plans.map(plan => {
                            return (
                                <HashLink smooth to='/#services' className='hashButton' key={plan.buttonText}>
                                    <Button 
                                        buttonText={plan.buttonText}
                                        buttonPath={plan.buttonPath}
                                        buttonClass={'home-banner-button'}
                                        buttonIcon={true}
                                        key={plan.buttonText}
                                    />
                                </HashLink>
                            )
                        })}
                    </div>
                </div>                
            </div>
        </HeroBannerContainer>
    );
};

export default HomeBanner;