import React from 'react';
import { Container } from 'react-bootstrap'

const HeroBannerContainer = ({children, overlay, bg, classes, ids}) => {
    return (
        <div 
            className={`section main-slider-1 ${overlay ? 'banner-overlay' : null} ${classes}`} 
            style={{backgroundImage: `url(${bg})`}}
            id={ids}
        >
            <Container style={{zIndex: 1}}>
                {children}
            </Container>
        </div>
    );
};

export default HeroBannerContainer;