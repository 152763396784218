import React from 'react'
import ServicesBanner from '../../../components/HeroBanner/ServicesBanner/ServicesBanner'
import TextSectionFour from '../../../components/TextSection/TextSectionFour/TextSectionFour'
import MethodExplanation from '../../../components/services/MethodExplanation/MethodExplanation'
import WorkStepsDescription from '../../../components/services/WorkStepsSection/WorkStepsDescription'
import PriceSection from '../../../components/services/PriceSection/PriceSection'
import Faqs from '../../Faqs/Faqs'
import pregnantService from '../../../data/pregnantService'
import  faqs from '../../../data/faqs'
import bannerPregnancy from '../../../assets/images/services/pregnancy-1.jpg'
import './pregnantService.scss'

const PregnantService = () => {
    return (
        <main className={'main-wrapper p-0'}>
            <ServicesBanner data={pregnantService.banner} overlay={true} bg={bannerPregnancy} />
            {/* <TextSectionThree data={pregnantService.section2} classes={''}/> */}
            <TextSectionFour data={pregnantService.info} classes={''} />
            <MethodExplanation data={pregnantService.method} />
            <WorkStepsDescription 
                data={pregnantService.workSteps} 
                classes={'steps-pregnant'} 
                serviceType={'pregnant'}
            />
            <PriceSection data={pregnantService.prices} pregnant={true} />
            <Faqs data={faqs.pregnant} />
        </main>
    )
}

export default PregnantService