import React from 'react'
import { useNavigate } from 'react-router-dom'
import { scrollTopWin } from '../../../util/helperFunctions'
import './button.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

const Button = ({ buttonText, buttonPath, buttonClass, type, buttonIcon, disabled, calendar }) => {
    const navigate = useNavigate();

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    const handleCalendarButton = () => {
        openInNewTab("https://www.doctoralia.es/lucila-glom/dietista-nutricionista/valencia")
    }

    const handleButton = () => { 
        navigate(`${buttonPath}`) 
        scrollTopWin()
    }

    return (
        <>
            {type === 'submit' ?
                <button disabled={disabled} type={type?.type} className={`button-content ${buttonClass}`}>{buttonText}</button>
                :
                <button 
                    type={type?.type} 
                    className={`button-content ${buttonClass}`} 
                    onClick={calendar === true ? handleCalendarButton : handleButton} role="link"
                >
                    {buttonText}
                    {buttonIcon &&
                        <FontAwesomeIcon  icon={faAngleDoubleRight} className='fa fa-angle-double-right button-deco filter-white' aria-hidden="true"/>
                    }
                </button>
            }
        </>
    )
}

export default Button