import React from 'react'
import ServicesBanner from '../../../components/HeroBanner/ServicesBanner/ServicesBanner'
import TextSectionTwo from '../../../components/TextSection/TextSectionTwo/TextSectionTwo'
import MethodExplanation from '../../../components/services/MethodExplanation/MethodExplanation'
import WorkStepsDescription from '../../../components/services/WorkStepsSection/WorkStepsDescription'
import PriceSection from '../../../components/services/PriceSection/PriceSection'
import Faqs from '../../Faqs/Faqs'
import generalService from '../../../data/generalService'
import faqs from '../../../data/faqs'
import bannerBg from '../../../assets/images/services/banner-services.png'
import './generalService.scss'

const GeneralService = () => {
    return (
        <main className={'main-wrapper p-0'}>
            <ServicesBanner data={generalService.banner} bg={bannerBg} overlay={true} />
            <TextSectionTwo data={generalService.info} classes={'general-service-text-section'}/>
            <MethodExplanation data={generalService.method} />
            <WorkStepsDescription data={generalService.workSteps} serviceType={'general'}/>
            <PriceSection data={generalService.prices} />
            <Faqs data={faqs.general} />
        </main>
    )
}

export default GeneralService