import React from 'react'
import { Link } from 'react-router-dom'
import Section from '../../container/Section/Section'
import { Col, Container, Row } from 'react-bootstrap'
import Content from '../content'
import Button from '../Buttons/Button/Button'
import './formation.scss'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import dot from '../../assets/images/about/dot.svg'
import linePath from '../../assets/images/about/lined-path.svg'


const Formation = ({data}) => {

    return (
        <Section>
            <Container>
                <Row className={'formation-text-row'}>
                    <Col lg={11}>
                        <Content classes={'formation-text-section'}>
                            <ReactMarkdown children={data.focus.text} className="focus-text" />
                            <Link to={data.focus.buttonPath} target='_blank' rel="noreferrer" className='about-me-button button-content button-deco formation-button'>
                                {data.focus.buttonText}
                            </Link>
                        </Content>
                    </Col>
                </Row>
                <Row className='formation-studies'>
                    <Col lg={5} className={'formation-studies-col-1'}>
                        <Content classes={'round-image'}>
                            <img src={require('../../assets/images/about/about4.png')} alt={data.formation.alt}/>
                        </Content>
                    </Col>

                    <Col lg={7} classes={'formation-studies-col-2'}>
                        <Content classes={'formation-studies-col-2'}>
                            {/* <div className='dotted-column'>
                                <img src={dotted} alt="thumb"/>
                            </div> */}

                            <div className='studies'>
                                <img src={linePath} alt='dotted'  className='line-path'/>
                                {data.formation.studies.map(study => {
                                    return (
                                        <div className='studies-item' key={study.id}>
                                            <img src={dot} alt='dot' className='dot' />
                                            <div className='studies-year'>
                                                <p >{study.year}</p>
                                            </div>
                                            <div className='studies-item-text'>
                                                <h5 className='studies-item-text-title'>{study.title}</h5>
                                                <span className='studies-item-text'>{study.text}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                           {/*  <button className={'about-me-button button-content'}>
                                <a href={data.cv.doc} download>
                                    <span>{data.cv.title}</span>
                                </a>
                            </button> */}
                        </Content>
                        {/* <Button buttonText={data.cv.title} buttonClass={'about-me-button button-green text-section-button'} >
                            <a href='/assets/cv.pdf' download>
                                <span>Download CV</span>
                            </a>
                        </Button> */}
                       
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default Formation