import React from 'react'
import { Link } from 'react-router-dom'
import {Container, Row, Col } from 'react-bootstrap'
import SocialMedia from '../../socialMedia/SocialMedia'
import NavItem from '../../NavItem/NavItem'
import logo from '../../../assets/images/logo/horizontal-light-logo.svg'
import footerData from '../../../data/footer'
import { scrollTopWin } from '../../../util/helperFunctions'
import './footer.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import DoctoraliaWidget from '../../widget/Doctoralia'


const Footer = ({position}) => {

    return (
        <div className={`footer-section section position-${position} pt-20 pb-5 footer-wrapper`}>
            <Container fluid>
                <Row className={'align-items-center'} style={{margin: '10px 50px 0px 50px',}}>
                    <Col lg={3} className={'footer-sections footer-section1'}>
                        <div className='footer-nav-section-wrapper'>
                            {/* <Logo classes={'footer-logo'} /> */}

                            <div className={` mb-20 footer-logo`}>
                                <Link to={'/'} onClick={scrollTopWin}>
                                    <img src={logo} alt="logo"/>
                                    {/* <img src={LogoLight} className="light-logo" alt="logo"/> */}
                                </Link>
                            </div>

                            <div className='section1-item'>
                                <FontAwesomeIcon icon={faMapMarker} className='fa fa-map-marker fa-2x fa-fw footer-icon filter-white' aria-hidden="true"/>
                                <p>{footerData.location.country}</p>
                            </div>
                            <div className='section1-item'>
                                <a href={'https://wa.me/message/RWQJ35EJ3EOLF1'} style={{display: 'flex'}} target="_blank" rel="noreferrer" >
                                    <FontAwesomeIcon icon={faWhatsapp} className="fa fa-whatsapp fa-2x fa-fw footer-icon filter-white" aria-hidden="true"/>
                                    <p>{footerData.location.phone}</p>
                                </a>                                
                            </div>
                        </div>
                    </Col>

                    <Col lg={3} className={'footer-sections footer-section2'}>
                        <div className='footer-nav-section-wrapper'>
                            <h6>{footerData.site.title}</h6>
                            <div className="footer-nav-list">
                                <ul>
                                    <NavItem data={footerData.site.sections}/>
                                </ul>
                            </div>
                        </div>
                    </Col>

                    <Col lg={3} className={'footer-sections footer-section2'}>
                            <div className='footer-nav-section-wrapper'>
                                <h6>{footerData.tools.title}</h6>
                                <div className="footer-nav-list">
                                    <ul>
                                        <NavItem data={footerData.tools.sections}/>
                                        <SocialMedia classes={'footer-social-icon filter-white'} outerWrapper={'footer-icon-wrapper'} />
                                    </ul>
                                </div>
                            </div>
                    </Col>

                    <Col lg={3}>
                        <DoctoraliaWidget type={'certificate'} />
                    </Col>
                    <div className='footer-divider'></div>
                </Row>
                
                <Row className='pt-20 pb-20'>
                    <Col className='align-items'>
                        <div className={'footer-copyright-wrapper'}>
                            <p>&copy; {new Date().getFullYear()} {footerData.rights.text}</p>
                        </div>
        
                        {/* <div className='footer-credits-wrapper'>
                            <p className='pr-10'>{footerData.credits.text}</p>
                            <Link to={'https://alexandrasaavedra.com/'} target="_blank">
                                {footerData.credits.link}
                            </Link>
                        </div> */}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer