import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import HeroBannerContainer from '../../../container/HeroBanner'
import Divider from '../../Divider/Divider'
import './servicesBanner.scss'

const ServicesBanner = ({data, classes, overlay, bg}) => {

    return (
        <HeroBannerContainer bg={bg} overlay={overlay} classes={classes}>
            <div className='main-slider-content'>
                <div className='services-banner-content'>
                    <div id='services-banner-title'>
                        <h1>{data.title}</h1>
                        <Divider classes={'services-banner-divider'} />
                    </div>
                    <div className='services-banner-text'>
                        <ReactMarkdown className='banner-subtitle' children={data.subtitle} />
                        <ReactMarkdown className='banner-text' children={data.text} />
                    </div>
                </div>
            </div>
        </HeroBannerContainer>
    )
}

export default ServicesBanner