import React from 'react';
import {Container, Row} from 'react-bootstrap'
import './index.scss'

const HeaderContainer = ({children, classes}) => {
    return (
        <div className={`header-section section ${classes}`} style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px'}}>
            <Container fluid>
                <Row className={'align-items-center'} style={{justifyContent: "space-between"}}>
                    {children}
                </Row>
            </Container>
        </div>
    );
};

export default HeaderContainer;
