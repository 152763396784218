import React from 'react'
import HeroBannerContainer from '../../../container/HeroBanner'
import SectionTitle from '../../SectionTitle/SectionTitle'
import Divider from '../../Divider/Divider'
import './aboutBanner.scss'
import bg from '../../../assets/images/about/aboutme.svg'

const AboutBanner = ({data}) => {
    return (
        <HeroBannerContainer overlay={false} bg={bg} classes={'about-banner'}>
            <div className="main-slider-content">
                    <div className='about-banner-text'>
                        <SectionTitle 
                            title={data.title}
                            content={data.subtitle}
                            classes={'about-banner-title'}
                            type={'banner'}
                        />
                        {/* <Divider classes={'about-banner-divider'} /> */}
                        <p> 
                            {data.text1} 
                        </p>
                    </div>
            </div>
        </HeroBannerContainer>
    )
}

export default AboutBanner