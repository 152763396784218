import React from 'react';
import './quickContact.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const QuickContact = ({phone, email, time}) => {
    return (
        <div className="contact-info-wrapper">
            <h3>CONTACTO</h3>
            <ul>
                <li>
                    {/* <span>Tel:</span> */}
                    <a href={'https://wa.me/message/RWQJ35EJ3EOLF1'} target="_blank" rel="noreferrer" >
                        <FontAwesomeIcon icon={faWhatsapp} className="fa fa-whatsapp fa-2x fa-fw contact-icon" aria-hidden="true" />
                        {phone}
                    </a>
                </li>
                <li>
                    <span>Email:</span>
                    <a href={`mailto:${email}`}>{email}</a>
                </li>
                <li>
                    <span>Horario:</span>{time}
                </li>
            </ul>
        </div>
    );
};

export default QuickContact;