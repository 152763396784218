import React from 'react'
import Section from '../../../container/Section/Section'
import Button from '../../Buttons/Button/Button'
import './textSectionOne.scss'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

const TextSectionOne = ({data, classes}) => {
    return (
        <Section classes={classes}>
            <div className='text-section-one-content'>
                <ReactMarkdown children={data.text} />
                {/* <p>
                    {data.text1 && data.text1}
                    {data.span1 &&<span>{data.span1}</span>}
                    {data.text2 && data.text2}
                    {data.span2 && <span>{data.span2}</span>}
                    {data.text3 && data.text3}
                    {data.span3 &&<span>{data.span3}</span>}
                </p>
                <p>
                    {data.text4 && data.text4}
                    {data.span4 && <span>{data.span4}</span>}
                </p> */}
                {data.buttonText && 
                    <Button buttonClass={'text-section-one-button'} buttonText={data.buttonText} buttonPath={data.buttonPath} calendar={data.calendar}/>}
            </div>
        </Section>
    )
}

export default TextSectionOne