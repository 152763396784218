import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
//import Section from '../Section/Section';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import InfoItem from '../../components/InfoItem/InfoItem';
import Divider from '../../components/Divider/Divider';
import './index.scss'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const Facts = ({data}) => {

    return (
        <section className={`section bg-grey pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50`}>
            <SectionTitle content={data.subtitle} classes={'facts-section-title'}/>
            <Divider />
            <Container>
                <Row style={{paddingTop: '15px'}}>
                    {data.facts.map(fact => {
                        return (
                            <Col lg={6} className={`${fact.class}`} key={fact.id}>
                                <InfoItem 
                                    classes={'info-fact-border'}
                                    itemClasses={'info-fact'}
                                    title={fact.factPercentage}
                                    content={fact.fact}
                                />
                            </Col>
                        )
                    })}
                </Row>
                
                <Row>
                    <Col lg={12}>
                        <div className='bottom-info-bg' style={{backgroundColor: '#F1F1F3'}}>
                            <div className='bottom-info'>
                                <ReactMarkdown className='bottom-info-text' children={data.text} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Facts;