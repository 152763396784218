import React from 'react'
import { Outlet } from 'react-router-dom';
import Header from '../../components/header/HeaderOne';
import SideHeader from '../../components/SideHeader';
import FooterThree from '../../components/footer/FooterThree';
import Footer from '../../components/footer/Footer/Footer';
import WhatsappButton from '../../components/Buttons/FloatingButton/WhatsappButton';
import Cookies from '../../components/Cookies/Cookies';
import DoctoraliaWidget from '../../components/widget/Doctoralia.jsx';

const Layout = () => {
    return (
        <div className={'main-wrapper p-0'}>
            <Header classes={'position-static'}/>
            <SideHeader mobile={true} />
            <Outlet />
            {/* <WhatsappButton /> */}
            <DoctoraliaWidget type={'button_calendar_floating_medium'} />
            {/* <FooterThree position={'static'} /> */}
            <Cookies />
            <Footer position={'static'}/>
        </div>
    )
}

export default Layout