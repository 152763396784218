import React, { useState} from 'react';
//import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { HashLink } from 'react-router-hash-link';
import {Container, Row, Col} from 'react-bootstrap'
import Section from '../../container/Section/Section'
import QuickContact from "../../components/QuickContact";
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Divider from '../../components/Divider/Divider';
import SocialMedia from '../../components/socialMedia/SocialMedia';
import Input from '../../components/input';
import Button from '../../components/Buttons/Button/Button';
//import Map from "../../components/map";
import { v4 as uuidv4 } from 'uuid';
import contactData from '../../data/contact'
import './contact.scss'

import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';



const Contact = ({ page = true }) => {
    const [ status, setStatus ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ displayForm, setDisplayForm ] = useState(true);
    const [consent, setConsent] = useState(false);

    const [ messageData, setMessageData ] = useState({
        name: '',
        phone: '',
        email: '', 
        message: ''
    })

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
    
        const formData = new FormData();

        for ( var key in messageData ) {
            formData.append(key, messageData[key]);
        }        
    
    
        fetch('contact.php', {
          method: 'POST',
          body: formData,
        })
          .then((response) => {
            setStatus({ type: response.ok ? 'success' : 'error' }) 
            setDisplayForm(response.ok ? false : true )
            setLoading(false)
          })
      };

    return (
        <Section classes={'contact-wrapper bg-grey'} >
        
            <SectionTitle 
                title={contactData.title}
                content={contactData.subtitle}
                classes={'contact-title'}
                titleSize={page === true ? 'main' : 'secondary'}
            />
            <Divider classes={'divider'} />
            
            <Container className='contact-container'>
                <Row>
                    <div className='contact-intro-subtitle'> 
                        {/* <ReactMarkdown children={contactData.contact.title3} /> */}
                        <h6>
                            {contactData.contact.title} 
                            <HashLink to='/plan-nutricional#faq'>{contactData.contact.link}</HashLink> 
                            {contactData.contact.title2}
                        </h6>
                    </div>
                    <div className='contact-intro'>
                        {contactData.contact.text.map(text => {
                            return (
                                <p key={uuidv4()}>{text}</p>
                            )
                        })}
                    </div>
                    <Col lg={6} className={'mb-sm-45 mb-xs-45'}>
                            {displayForm === true ?
                                <div className="contact-form">
                                    <form id="contact-form" onSubmit={handleSubmit}>
                                        {contactData.form.map(field => {
                                            return (
                                                <Input 
                                                    key={field.name}
                                                    type={field.type} 
                                                    id={field.id} 
                                                    name={field.name} 
                                                    placeholder={field.title} 
                                                    required={field.required}
                                                    onChange={(event) => setMessageData({...messageData, [event.target.name]: event.target.value})}
                                                />
                                            )
                                        })}
                                        <PrivacyPolicy />
                                        <Button 
                                            disabled={loading}
                                            type={'submit'} 
                                            buttonText={contactData.buttonText} 
                                            buttonClass={contactData.buttonClass}
                                        />
                                    </form>
                                </div>

                                :
                                <>
                                    {status?.type === 'success' && 
                                        <Alert variant="success" >
                                            <Alert.Heading>{contactData.successMessage}</Alert.Heading>
                                        </Alert>
                                    }
                                </>
                            }

                            {loading &&
                                <div className='spinner-overlay'>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Enviando...</span>
                                    </Spinner>
                                </div>
                            }
                            
                            <>
                                {status?.type === 'error' && 
                                    <Alert variant='danger' className="mt-20">
                                        <p>{`${contactData.errorMessage}`}</p>
                                    </Alert>
                                }
                            </>

                        
                    </Col>

                    <Col lg={6} className='contact-info-wrapper'>
                        <div className='contact-info'>
                            <QuickContact
                                phone={contactData.contact.phone}
                                email={contactData.contact.email}
                                time={contactData.contact.time}
                            />
                            <SocialMedia 
                                title={'Redes Sociales'} 
                                classes={'contact-social-icon'} 
                                outerWrapper={'icon-outer-wrapper'} 
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default Contact;