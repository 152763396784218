import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import Section from '../../../container/Section/Section'
import { Container, Row, Col } from 'react-bootstrap'
import Content from '../../content'
import { v4 as uuidv4 } from 'uuid';
import './index.scss'

const MethodExplanation = ({data}) => {
    return (
        <Section classes={'general-services-wrapper'}>
            <Container>
                <Row className={'general-services'}>
                    <Col lg={5}>
                        <Content classes='general-services-title'>
                            <h3>{data.title}</h3>
                        </Content>
                    </Col>

                    <Col offset-md-1="true"></Col>

                    <Col lg={6} className='col-two-wrapper'>
                        <Content classes='col-two'> 
                            {data.text.map(text => {
                                return (
                                    <ReactMarkdown children={text} key={uuidv4()} className='method-text' />
                                )
                            })}    
                            <div className='highlight-text-wrapper'>
                                { data.highlightedText && 
                                    <ReactMarkdown children={data.highlightedText} />
                                }
                            </div>
                        </Content>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default MethodExplanation