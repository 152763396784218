import React from 'react';
import aboutmeInfo from '../../data/about-me/index.json'
import AboutBanner from '../../components/HeroBanner/AboutBanner/AboutBanner';
import TextSectionThree from '../../components/TextSection/TextSectionThree/TextSectionThree';
import Formation from '../../components/Formation/Formation';
import './aboutMe.scss'

const AboutPage = () => {
    return (
        <main className={'main-wrapper p-0'}>
            <AboutBanner data={aboutmeInfo.banner} />
            {/* <Divi  /> */}
            <TextSectionThree data={aboutmeInfo.aboutInfo} classes={'about-personal-info'} />
            <Formation data={aboutmeInfo} />
        </main>
    );
};

export default AboutPage;