import React from 'react'
import Section from '../../../container/Section/Section'
import { Container, Row, Col } from 'react-bootstrap'
import Content from '../../content'
import SectionTitle from '../../SectionTitle/SectionTitle'
import InfoItem from '../../InfoItem/InfoItem'
import num1 from '../../../assets/images/services/num-1.png'
import num2 from '../../../assets/images/services/num-2.png'
import num3 from '../../../assets/images/services/num-3.png'
import num4 from '../../../assets/images/services/num-4.png'
import dottedImg from '../../../assets/images/services/dotted-line.png'
import './index.scss'
import Thumbnail from '../../thumbnail'

const images = [ num1, num2, num3, num4]; 

const WorkStepsDescription = ({data, classes, serviceType}) => {

    return (
        <Section>
            <Container >
                <Row className='steps-container'>
                    <Col lg={8}>
                        <Content>
                            <SectionTitle 
                                title={data.title} 
                                content={data.subtitle} 
                                classes={'steps-title'}
                                titleSize={'secondary'}
                                type={'banner'}
                            />

                            <div className={`steps-items-list-container ${classes}`}>
                                <img src={dottedImg} alt='dotted'  className='dotted'/>

                                {data.steps.map((detail, index) => {
                                    return (
                                        <div className='steps-item-inner' key={detail.id}>
                                            <img src={images[index]} alt={detail.alt} className='circle-image'/>
                                            <InfoItem
                                                title={detail.title}
                                                content={detail.text}
                                                year={detail.year && detail.year}
                                                classes={'item-text'}
                                                link={detail.link}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </Content>
                    </Col>
                    
                    {serviceType !== 'pregnant' &&
                        <Col lg={4} style={{paddingRight: '0px'}}>    
                            <Thumbnail 
                                classes={'mb-md-30 mb-sm-30 mb-xs-30 mt-xs-30 mt-sm-30 mt-md-30 measurement-img'} 
                                thumb={`services/measure.jpg`}
                            />
                        </Col>
                    }
                </Row>
            </Container>
        </Section>
    )
}

export default WorkStepsDescription