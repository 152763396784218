import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { scrollTopWin } from '../../util/helperFunctions';
import './index.scss'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';

const NavItem = ({ data, sideHeader, onClick, calendar }) => {
    const [ open, setOpen ] = useState(false);

    const handleClick = (hasSubmenu) => {
        if(hasSubmenu) {
            setOpen(!open)
        } else {
            onNavLinkClick()
        }
    }

    const onNavLinkClick = () => {
        scrollTopWin()
        if(sideHeader && onClick) onClick()
    };

    return (
        data.map(navItem => (
            <li key={navItem.id} className={navItem.subMenu ? 'menu-item-has-children' : null}>
                {navItem.targetBlank ?
                    <Link to={navItem.link} target='_blank'>{navItem.title}</Link>
                    :
                    <NavLink
                        end={navItem.link === `/`}
                        to={`${process.env.PUBLIC_URL + navItem.link}`}
                        className={'active'}
                        onClick={() => handleClick(navItem.subMenu)}
                        id='sidebar-item'
                    >
                        {navItem.title}
                        {(navItem.subMenu) && 
                            (open ? 
                            <FontAwesomeIcon icon={faAngleDoubleUp} className="fa fa-angle-double-down accordion-sidebar" aria-hidden="true"/> 
                            : 
                            <FontAwesomeIcon icon={faAngleDoubleDown} className="fa fa-angle-double-down accordion-sidebar" aria-hidden="true"/>)
                        }
                    </NavLink>

                }

                {(navItem.subMenu && (open || !sideHeader)) && (
                    <ul className="sub-menu">
                        {navItem.subMenu.map(subItem => {
                            return (
                                <li key={subItem.id} className={subItem.subMenu ? "menu-item-has-children" : null}>
                                    <NavLink to={`${process.env.PUBLIC_URL + subItem.link}`} onClick={onNavLinkClick}>{subItem.title}</NavLink>
                                </li>
                            )
                        })}
                    </ul>
                )}
            </li>
        ))
    );
};

export default NavItem;