import React from 'react'
import Section from '../../../container/Section/Section'
import SectionTitle from '../../SectionTitle/SectionTitle'
import Divider from '../../Divider/Divider'
import { Container, Row } from 'react-bootstrap'
import PriceItem2 from '../../../components/PriceItem/PriceItemTwo'
import { v4 as uuidv4 } from 'uuid';
import './index.scss'

const PriceSection = ({data, pregnant}) => {

    return (
        <Section classes={'price-section-wrapper'}>
            <div className='price-section-title-wrapper'>
                <SectionTitle 
                    title={data.title} 
                    content={data.subtitle} 
                    classes={'price-title'}
                    titleSize={'secondary'}
                    type={'secondary'}
                />
                <Divider />
            </div>

            <Container>
                <Row className='price-list'>
                    {data.plans.map(plan => {
                        return (
                            <PriceItem2 data={plan}  key={uuidv4()}/>
                        )
                    })}
                </Row>
            </Container>
            {pregnant && 
                <div className='extra-info'>
                    <p>{data.footnote}</p>
                </div>
            }
        </Section>
    )
}

export default PriceSection