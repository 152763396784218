import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Container, Row, Col } from 'react-bootstrap'
import Content from "../../content";
import Section from '../../../container/Section/Section';
import { v4 as uuidv4 } from 'uuid';
import './TextSectionThree.scss'

const TextSectionThree = ({type, data, classes}) => {

    return (
        <Section classes={classes}>
            <Container>
                <Row className={'align-items-center divided-section-inner-container'}>
                    
                    <Col lg={4}>
                        <Content classes={'divided-section-col1'}>
                            <h2 className="">{data.title}</h2>
                        </Content>
                    </Col>
                    <Col lg={6}>
                        <Content classes={'divided-section-col2'}>
                            <p className='subtitle'>{data.subtitle}</p>
                            {data.text.map(paragraph => {
                                return <ReactMarkdown  className='text-grey about-me-text' children={paragraph} key={uuidv4()} />
                            })}
                        </Content>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default TextSectionThree;