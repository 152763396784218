import './index.scss'

const InfoItem = ({title, content, year, classes, itemClasses, link}) => {
    return (
        <div className={`info-item-container ${classes}`}>
            <div className={itemClasses}>
                {title && <h3>{title}</h3>}
                <p>
                    {content}
                    <a 
                        href='https://www.youtube.com/watch?v=kIJgUWLLxXM' 
                        rel="noreferrer" 
                        target='_blank' 
                        className='link'
                    >
                        {link && link}
                    </a>
                </p>
                {year && <p>{year}</p>}
            </div>
        </div>
    );
};

export default InfoItem;