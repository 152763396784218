import useScript from "../../hooks/useScript";

const DoctoraliaWidget = ({type}) => {
    useScript("//platform.docplanner.com/js/widget.js");

    return (
        <div>
             <a style={{height: '600px', marginTop: '100px', color: 'purple'}} 
                id="zl-url" class="zl-url" 
                href="https://www.doctoralia.es/lucila-glom/dietista-nutricionista/valencia" 
                rel="nofollow" 
                data-zlw-doctor="lucila-glom" 
                data-zlw-type={type}
                data-zlw-opinion="false" 
                data-zlw-hide-branding="true" 
                data-zlw-saas-only="false">Lucila Glom - Doctoralia.es</a>
        </div>
    )
};

export default DoctoraliaWidget